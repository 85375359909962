import { useDashboard } from "@/contexts/dashboard/DashboardContext";
import SpinnerContainer from "@/components/dashboard/SpinnerContainer";
import MonthlyViewLineChart from "@/contexts/dashboard/MonthlyViewLineChart";
import TemplatesIcon from "@/components/svg/TemplatesIcon";
import LineGraphic from "@/components/svg/LineGraphic";

const MonthlyView: React.FC = () => {
    const { isFetchingMonthlyView } = useDashboard();

    return (
        <div className='flex flex-col items-start w-full h-full overflow-x-auto overflow-y-hidden border rounded-lg bg-white' >
            {isFetchingMonthlyView && <SpinnerContainer />}
            <div className="flex items-center justify-between w-fit space-x-4 rounded-br-2xl bg-gradient-to-r from-orange-500 to-amber-400 px-4 py-1">
                <span className="text-left text-xl text-white font-medium" >Total de Impressões por Dia</span>
                <LineGraphic className="stroke-white" />
            </div>
            <MonthlyViewLineChart />
        </div>
    )
}
export default MonthlyView;