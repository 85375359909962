import React from 'react';
import Spinner from '@/components/svg/Spinner';

const SpinnerContainer: React.FC = () => {
	return (
		<div className="fixed top-0 right-0 p-2">
			<Spinner />
		</div>
	);
}

export default SpinnerContainer;
