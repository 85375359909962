import React, { useState } from 'react';
import ImagePreviewModal from '@/components/modals/common/ImagePreviewModal';
import { Orientation } from '@/types/canvas';

type ImageRowPreviewProps = {
	uri: string;
	format?: Orientation;
	isOptionModal?: boolean;
}

const ImageRowPreview: React.FC<ImageRowPreviewProps> = ({ format, uri, isOptionModal = false }) => {
	const [isPreviewImageModalOpen, setIsPreviewImageModalOpen] = useState(
		false
	);

	const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
		event.preventDefault();
		setIsPreviewImageModalOpen(true);
	};

	let previewDimensions = '';
	switch (format) {
		case 'vertical':
		case 'story':
			previewDimensions = isOptionModal ? 'w-34 h-44' : 'w-25 h-32';
			break;
		case 'horizontal':
			previewDimensions = isOptionModal ? 'w-38 h-28' : 'w-32 h-25';
			break;
		case 'p30':
		case 'p21':
			previewDimensions = isOptionModal ? 'w-26 h-12' : 'w-25 h-10';
			break;
		case 'post':
			previewDimensions = isOptionModal ? 'w-26 h-26' : 'w-20 h-20';
			break;
		default:
			previewDimensions = 'w-10 h-10';
	}

	return (
		<div className='flex justify-center items-center'>
			<ImagePreviewModal
				src={uri}
				format={format}
				isOpen={isPreviewImageModalOpen}
				onClose={() => setIsPreviewImageModalOpen(false)}
			/>
			<img
				src={uri}
				alt="Poster preview"
				onClick={handleClick}
				className={`${previewDimensions} rounded-md border border-zinc-300 hover:border-orange-500 object-cover transition-transform duration-300 hover:scale-110 cursor-pointer`}
			/>
		</div>
	);
};

export default ImageRowPreview;
