import React, { useEffect, useMemo, useRef, useState } from 'react';
import HandleModal from "@/components/modals/BlurModal";
import { ModalInterface } from "@/types";
// @ts-ignore
import Zoom from 'react-img-zoom'
import CIcon from '@coreui/icons-react';
import { freeSet } from '@coreui/icons';
import { Orientation } from '@/types/canvas';

interface ImagePreviewModalInterface extends ModalInterface {
    src: string | null,
    duration?: number,
    format?: Orientation
}

const DEFAULT_DURATION = 1;

const ZOOM_INCREMENT: number = 1;
const INITIAL_ZOOM_VALUE: number = 1;
const MAX_ZOOM_VALUE: number = 5;

const ImagePreviewModal: React.FC<ImagePreviewModalInterface> = ({ isOpen, onClose, src, duration, format }) => {
    const [zoomScale, setZoomScale] = useState(INITIAL_ZOOM_VALUE);
    const [isZoomEnabled, setIsZooEnabled] = useState(false);
    const [isVertical, setIsVertical] = useState(false);

    const [width, setWidth] = useState<number>(450); // Inicia com tamanho vertical.
    const [height, setHeight] = useState<number>(600);

    const image = useMemo(() => new Image(), [src]);

    useEffect(() => {
        if (!isOpen) {
            setZoomScale(INITIAL_ZOOM_VALUE);
            setIsZooEnabled(false);
        }
    }, [isOpen])

    useEffect(() => {
        if (src) {
            image.src = src;
            image.onload = () => {
                // Get the image dimensions
                const { width, height } = image;
                setWidth(width);
                setHeight(height);
                if (format === undefined) {
                    if (height > width) {
                        setWidth(450);
                        setHeight(600);
                    }
                    else {
                        setWidth(700);
                        setHeight(500);
                    }
                }
                else {
                    switch (format) {
                        case 'vertical':
                        case 'story':
                            setWidth(450);
                            setHeight(600);
                            break;
                        case 'horizontal':
                            setWidth(700);
                            setHeight(500);
                            break;
                        case 'p30':
                        case 'p21':
                            setWidth(750);
                            setHeight(250);
                            break;
                    }
                }
            };
        }
    }, [image]);

    const zoomHandler = (value: number, useAbsoluteValue: boolean = false) => {
        setZoomScale(useAbsoluteValue ? value : get_zoom_level(value));
    }

    const toggleZoom = () => {
        if (!isZoomEnabled) setIsZooEnabled(true);
        zoomHandler(zoomScale);
    }

    const disableZoom = () => {
        setIsZooEnabled(false);
        zoomHandler(1, true);
    }

    return (
        <HandleModal onClose={onClose} isOpen={isOpen}>
            <div className={`relative w-full h-full bg-slate-50 p-2 rounded-md ${isZoomEnabled ? (zoomScale == MAX_ZOOM_VALUE ? 'cursor-zoom-out' : 'cursor-zoom-in') : 'cursor-crosshair'}`} >
                <div className="w-fit h-fit z-10" onClick={toggleZoom}>
                    <Zoom
                        img={src}
                        zoomScale={zoomScale}
                        width={width}
                        height={height}
                        transitionTime={duration || DEFAULT_DURATION}
                    />
                </div>
                {
                    isZoomEnabled && (
                        <div onClick={disableZoom} className='absolute z-20 right-0 bottom-0 cursor-pointer w-fit text-gray-700 bg-blue-100/75 px-2 py-1 rounded-lg m-3'>
                            <div className='flex flex-row justify-around items-center' onClick={disableZoom}>
                                <span className="text-sm">{zoomScale.toFixed(1)}x - 5.0x</span>
                                <div className="m-2">
                                    <CIcon icon={freeSet.cilReload} width={15} size="xxl" />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </HandleModal>
    )
}

ImagePreviewModal.defaultProps = {
    duration: DEFAULT_DURATION
}

export default ImagePreviewModal;

function get_zoom_level(value: number): number {
    switch (value) {
        case MAX_ZOOM_VALUE: return INITIAL_ZOOM_VALUE;
        default: return value + ZOOM_INCREMENT;
    }
}
