import { FC } from "react"

interface ICalendarDashboard {
    isOpen?: (value: boolean) => void,
    selectDate: string;
    onDateChange?: (date: string) => void,

}
const CalendarDashboard: FC<ICalendarDashboard> = ({ isOpen, selectDate, onDateChange }) => {

    const halterDate = (event: any) => {
        if (onDateChange) {
            onDateChange(event.target.value);
        }
    }
    return (
        <>
            <input type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" value={selectDate} onChange={halterDate}></input>
        </>

    )

}
export default CalendarDashboard;