import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps, AreaChart, Area, Dot } from 'recharts';
import { useDashboard } from '@/contexts/dashboard/DashboardContext';

const MonthlyViewLineChart = () => {
	const { monthlyViewData } = useDashboard();

	// Organiza os dados para que cada entrada na matriz de dados represente um único ponto no eixo x
	const organizedData = monthlyViewData[0]?.points.map((_, i) => ({
		x: i + 1,
		...monthlyViewData.reduce((obj, { label, points, color }) => ({ ...obj, [label]: points[i]?.y }), {}),
	}));
	const renderTooltip = (props: TooltipProps<number, string>) => {
		const { payload } = props;

		const TooltipItems: React.FC = () => {
			return (
				<React.Fragment>
					{
						payload?.map((entry, index) => {
							if (entry.value && entry.value > 0) {
								return (
									<li
										key={`item-${index}`}
										className="text-blue-500"
									>
										{entry.dataKey}: {entry.value}
									</li>
								);
							}
							else if (index === 0) {
								return (
									<li
										key={`item-${index}`}
										className="text-blue-500"
									>
										Vazio
									</li>
								);
							}
							return null;
						})
					}
				</React.Fragment>
			)
		}

		return (
			<ul className="bg-blue-100 p-2">
				<TooltipItems />
			</ul>
		);
	};

	return (
		<ResponsiveContainer width="100%">
			<AreaChart
				data={organizedData} // substituir 
				margin={{
					top: 20,
					right: 50,
					bottom: 10,
					left: 0,
				}}
			>
				<CartesianGrid stroke="#f5f5f5" />
				<XAxis dataKey="x" scale="point" />
				<YAxis />
				<Tooltip content={renderTooltip} />
				<Legend />
				{monthlyViewData.map((lineData, index) => (
					<Area
						key={`area-${index}`}
						type="bump" //'basis' | 'basisClosed' | 'basisOpen' | 'bumpX' | 'bumpY' | 'bump' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
						dataKey={lineData.label}
						stroke={"#1a8cff"}
						fill={"#1a8cff"}
						dot={<Dot fill="#fff" stroke={"#0073e6"} r={5} />}
					/>
				))}
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default MonthlyViewLineChart;