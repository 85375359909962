import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps, AreaChart, Area, Dot, BarChart, Bar, LabelList, Cell, Label } from 'recharts';
import { useDashboard } from '@/contexts/dashboard/DashboardContext';
import { useCompany } from '../company/CompanyContext';

const BarStoreViewLineChart = () => {
	const { marketsViewData, monthlyViewData } = useDashboard();
	const { company } = useCompany();

	return (
		<ResponsiveContainer width="100%">
			<BarChart
				width={500}
				height={250}
				maxBarSize={50}
				data={marketsViewData}
				margin={{ top: 55, right: 30, left: 0, bottom: 10 }}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey='label' />
				<Tooltip />
				<YAxis />
				<Bar dataKey="quantidade" fill="#1a8cff" >
					<LabelList dataKey="label" position="insideTopLeft" angle={-55} fill="#000000" style={{ fontWeight: 'bold' }} />
					{/* <LabelList dataKey="label" position="top" fill='#000000' /> */}
					<LabelList fill="#fff" dataKey="quantidade" position="inside"  style={{ fontWeight: 'bold' }}/>
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default BarStoreViewLineChart;